body {
  margin: 0;
  font-family: 'Oxygen', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
}

.App .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.7rem;
  color: white;
}

.App .App-header .Elysium-logo {
  pointer-events: none;
  width: 25rem;
  filter: drop-shadow(20px 10px 4px rgba(0, 0, 0, 0.18));
}

.App .App-header sup.tm {
  font-size: 1.1rem;
}

.App .App-header .icon-links {
  display: flex;
  margin-top: 2rem;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.App .icon-link {
  cursor: pointer;
  background-color: #2c3038;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.24);
  padding: 16px;
  width: 2.1rem;
  height: 2.1rem;
  margin-right: 24px;
  transition: background-color 180ms ease-in-out;
}

.App .icon-link:last-of-type {
  margin-right: 0;
}

.App .icon-link img {
  width: 100%;
}

.App .icon-link:hover {
  background-color: #31353d;
}

@media only screen and (max-width: 450px) {
  .App .App-header .Elysium-logo {
    width: 82vw !important;
  }
  .App .App-header .icon-link {
    border-radius: 20px;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.24);
    padding: 15px;
    width: 2rem;
    height: 2rem;
    margin-right: 16px;
  }
}
